import { createSlice } from "@reduxjs/toolkit";

export const LoaderDashboard = createSlice({
    name: "search",
    initialState: {
        loaderValue: false
    },
    reducers: {
        loaderData: (state, action) => {
            state.loaderValue = action.payload;
        },
    }
});

export const { loaderData } = LoaderDashboard.actions;

export default LoaderDashboard.reducer;
